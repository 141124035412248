// react | next
import type { ChangeEvent } from 'react';
// styles
import styles from './Dropdown.module.scss';
// interfaces | enums
import type { IDropdownProps } from './IDropdownProps';
import { EOptionsThemeColor } from '@enums/EOptionsThemeColor';

export const Dropdown = ({
	label,
	options,
	optional = false,
	selected = '',
	onSelect,
	theme = EOptionsThemeColor.DARK,
}: IDropdownProps) => {
	const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
		onSelect(e.currentTarget.value);
	};

	return (
		<div className={`${styles.dropdown} ${styles[theme]}`}>
			<label className='sr-only'>{label}</label>
			<select onChange={handleSelect} className={styles.select} value={selected}>
				<option disabled={!optional} value=''>
					{label}
				</option>
				{options.map(({ label, value }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
			<i className={`fas fa-chevron-down ${styles.chevron}`}></i>
		</div>
	);
};
