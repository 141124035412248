export const states = [
	{
		title: 'Alabama',
		abbreviation: 'al',
		svgPath:
			'm643 467.4.4-7.3-.9-1.2-1.7-.7-2.5-2.8.5-2.9 48.8-5.1-.7-2.2-1.5-1.5-.5-1.4.6-6.3-2.4-5.7.5-2.6.3-3.7 2.2-3.8-.2-1.1-1.7-1v-3.2l-1.8-1.9-2.9-6.1-12.9-45.8-45.7 4 1.3 2-1.3 67 4.4 33.2.9-.5 1.3.1.6.4.8-.1 2-3.8v-2.3l1.1-1.1 1.4.5 3.4 6.4v.9l-3.3 2.2 3.5-.4 4.9-1.6z',
	},
	{ title: 'Alaska', abbreviation: 'ak' },
	{
		title: 'Arizona',
		abbreviation: 'az',
		svgPath:
			'm139.6 387.6 3-2.2.8-2.4-1-1.6-1.8-.2-1.1-1.6 1.1-6.9 1.6-.3 2.4-3.2 1.6-7 2.4-3.6 4.8-1.7 1.3-1.3-.4-1.9-2.3-2.5-1.2-5.8-1.4-1.8-1.3-3.4.9-2.1 1.4-3 .5-2.9-.5-4.9 1-13.6 3.5-.6 3.7 1.4 1.2 2.7h2l2.4-2.9 3.4-17.5 46.2 8.2 40 6-17.4 124.1-37.3-5.4-64.2-37.5.5-2.9 2-1.8z',
	},
	{
		title: 'Arkansas',
		abbreviation: 'ar',
		svgPath:
			'm584.2 367 .9-2.2 1.2.5.7-1-.8-.7.3-1.5-1.1-.9.6-1-.1-1.5-1.1-.1.8-.8 1.3.8.3-1.4-.4-1.1.1-.7 2 .6-.4-1.5 1.6-1.3-.5-.9-1.1.1-.6-.9.9-.9 1.6-.2.5-.8 1.4-.2-.1-.8-.9-.9v-.5h1.5l.4-.7-1.4-1-.1-.6-11.2.8 2.8-5.1 1.7-1.5v-2.2l-1.6-2.5-39.8 2-39.1.7 4.1 24.4-.7 39 2.6 2.3 2.8-1.3 3.2.8.2 11.9 52.3-1.3 1.2-1.5.5-3-1.5-2.3-.5-2.2.9-.7v-.8l-1.7-1.1-.1-.7 1.6-.9-1.2-1.1 1.7-7.1 3.4-1.6v-.8l-1.1-1.4 2.9-5.4h1.9l1.5-1.2-.3-5.2 3.1-4.5 1.8-.6-.5-3.1z',
	},
	{
		title: 'California',
		abbreviation: 'ca',
		svgPath:
			'm69.4 365.6 3.4 5.2-1.4.1-1.8-1.9zm1.9-9.8 1.8 4.1 2.6 1 .7-.6-1.3-2.5-2.6-2.4zm-19.9-19v2.4l2 1.2 4.4-.2 1-1-3.1-.2zm-5.9.1 3.3.5 1.4 2.2h-3.8zm47.9 45.5-1-3 .2-3-.4-7.9-1.8-4.8-1.2-1.4-.6-1.5-7-8.6-3.6.1-2-1.9 1.1-1.8-.7-3.7-2.2-1.2-3.9-.6-2.8-1.3-1.5-1.9-4.5-6.6-2.7-2.2-3.7-.5-3.1-2.3-4.7-1.5-2.8-.3L42 324l.2-2.8.8-4.8 1.8-5.1-1.4-1.6-4-9.4-2.7-3.7-.4-3-1.6-2.3.2-2.5-2-5-2.9-2.7.6-7.1 2.4-.8 1.8-3.1-.4-3.2-1-.9h-2.5l-2.5-3.3-1.5-3.5v-7.5l1.2-4.2.2-2.1 2.5.2-.1 1.6-.8.7v2.5l3.7 3.2v-4.7l-1.4-3.4.5-1.1-1-1.7 2.8-1.5-1.9-3-1.4.5-1.5 3.8.5 1.3-.8 1-.9-.1-5.4-6.1.7-5.6-1.1-3.9-6.5-12.8.8-10.7 2.3-3.6.2-6.4-5.5-11.1.3-5.2 6.9-7.5 1.7-2.4-.1-1.4 4-9.2.1-8.4.9-2.5 66.1 18.6-16.4 63.1 1.1 3.5 70.4 105-.9 2.1 1.3 3.4 1.4 1.8 1.2 5.8 2.3 2.5.4 1.9-1.3 1.3-4.8 1.7-2.4 3.6-1.6 7-2.4 3.2-1.6.3-1.1 6.9 1.1 1.6 1.8.2 1 1.6-.8 2.4-3 2.2-2.2-.1z',
	},
	{
		title: 'Colorado',
		abbreviation: 'co',
		svgPath: 'm374.6 323.3-16.5-1-51.7-4.8-52.6-6.5 11.5-88.3 44.9 5.7 37.5 3.4 33.1 2.4-1.4 22.1z',
	},
	{
		title: 'Connecticut',
		abbreviation: 'ct',
		svgPath:
			'm873.5 178.9.4-1.1-3.2-12.3-.1-.3-14.9 3.4v.7l-.9.3-.5-.7-10.5 2.4 2.8 16.3 1.8 1.5-3.5 3.4 1.7 2.2 5.4-4.5 1.7-1.3h.8l2.4-3.1 1.4.1 2.9-1.1h2.1l5.3-2.7 2.8-.9 1-1 1.5.5z',
	},
	{
		title: 'Delaware',
		abbreviation: 'de',
		svgPath:
			'm822.2 226.6-1.6.3-1.5 1.1-1.2 2.1 7.6 27.1 10.9-2.3-2.2-7.6-1.1.5-3.3-2.6-.5-1.7-1.8-1-.2-3.7-2.1-2.2-1.1-.8-1.2-1.1-.4-3.2.3-2.1 1-2.2z',
	},
	{
		title: 'Florida',
		abbreviation: 'fl',
		svgPath:
			'm751.7 445.1-4-.7-1.7-.9-2.2 1.4v2.5l1.4 2.1-.5 4.3-2.1.6-1-1.1-.6-3.2-50.1 3.3-3.3-6-48.8 5.1-.5 2.9 2.5 2.8 1.7.7.9 1.2-.4 7.3-1.1.6.5.4 1-.3.7-.8 10.5-2.7 9.2-.5 8.1 1.9 8.5 5 2.4.8 2.2 2-.1 2.7h2.4l1.9-1 2.5.1 2-.8 2.9-2 3.1-2.9 1.1-.4.6.5h1.4l.5-.8-.5-1.2-.6-.6.2-.8 2-1.1 5-.4.8 1 1 .1 2.3 1 3 1.8 1.2 1.7 1.1 1.2 2.8 1.4v2.4l2.8 1.9 1 .1 1.6 1.4.7 1.6 1 .2.8 2.1.7.6 1-1.1 2.9.1.5 1.4 1.1.9v1.3l2.9 2.2.2 9.6-1.8 5.8 1 1.2-.2 3.4-.8 1.4.7 1.2 2.3 2.3.3 1.5.8 1-.4-1.9 1.3-.6.8-3.6-3-1.2.1-.6 2.6-.4.9 2.6 1.1.6.1-2 1.1.3.6.8-.1.7-2.9 4.2-.2 1.1-1.7 1.9v1.1l3.7 3.8 5.3 7.9 1.8 2.1v1.8l2.8 4.6 2.3.6.7-1.2-2.1.3-3-4.5.2-1.4 1.5-.8v-1.5l-.6-1.3.9-.9.4.9.7.5v4l-1.2-.6-.8.9 1.4 1.6 1 2.6 1.2-.6 2.3 1.2 2.1 2.2 1.6 5.1 3.1 4.8.8-1.3 2.8-.5 3.2 1.3.3 1.7 3.3 3.8.1 1.1 2.2 2.7-.7.5v2.7l2.7 1.4h1.5l2.7-1.8 1.5.3 1.1.4 2.3-1.7.2-.7 1.2.3 2.4-1.7 1.3-2.3-.7-3.2-.2-1.3 1.1-4 .6-.2.6 1.6.8-1.8-.8-7.2-.4-10.5-1-6.8-.7-1.7-6.6-11.1-5.2-9.1-2.2-3.3-1.3-3.6-.2-3.4.9-.3v-.9l-1.1-2.2-4-4-7.6-9.7-5.7-10.4-4.3-10.7-.6-3.7-1.2-1-.5-3.8zm9.2 134.5 1.7-.1-.7-1zm7.3-1.1v-.7l1.6-.2 3.7-3.3 1.5-.6 2.4-.9.3 1.3 1.7.8-2.6 1.2h-2.4l-3.9 2.5zm17.2-7.6-3 1.4-1 1.3 1.1.1zm3.8-2.9-1.1.3-1.4 2 1.1-.2 1.5-1.6zm8.3-15.7-1.7 5.6-.8 1-1 2.6-1.2 1.6-.7 1.7-1.9 2.2v.9l2.7-2.8 2.4-3.5.6-2 2.1-4.9z',
	},
	{
		title: 'Georgia',
		abbreviation: 'ga',
		svgPath:
			'M761.8 414.1v1.4l-4.2 6.2-1.2.2 1.5.5v2l-.9 1.1-.6 6-2.3 6.2.5 2 .7 5.1-3.6.3-4-.7-1.7-.9-2.2 1.4v2.5l1.4 2.1-.5 4.3-2.1.6-1-1.1-.6-3.2-50.1 3.3-3.3-6-.7-2.2-1.5-1.5-.5-1.4.6-6.3-2.4-5.7.5-2.6.3-3.7 2.2-3.8-.2-1.1-1.7-1v-3.2l-1.8-1.9-2.9-6.1-12.9-45.8 22.9-2.9 21.4-3-.1 1.9-1.9 1-1.4 3.2.2 1.3 6.1 3.8 2.6-.3 3.1 4 .4 1.7 4.2 5.1 2.6 1.7 1.4.2 2.2 1.6 1.1 2.2 2 1.6 1.8.5 2.7 2.7.1 1.4 2.6 2.8 5 2.3 3.6 6.7.3 2.7 3.9 2.1 2.5 4.8.8 3.1 4.2.4z',
	},
	{ title: 'Hawaii', abbreviation: 'hi' },
	{
		title: 'Idaho',
		abbreviation: 'id',
		svgPath:
			'm165.3 183.1-24.4-5.4 8.5-37.3 2.9-5.8.4-2.1.8-.9-.9-2-2.9-1.2.2-4.2 4-5.8 2.5-.8 1.6-2.3-.1-1.6 1.8-1.6 3.2-5.5 4.2-4.8-.5-3.2-3.5-3.1-1.6-3.6 1.1-4.3-.7-4 12.7-56.1 14.2 3-4.8 22 3.7 7.4-1.6 4.8 3.6 4.8 1.9.7 3.9 8.3v2.1l2.3 3h.9l1.4 2.1h3.2v1.6l-7.1 17-.5 4.1 1.4.5 1.6 2.6 2.8-1.4 3.6-2.4 1.9 1.9.5 2.5-.5 3.2 2.5 9.7 2.6 3.5 2.3 1.4.4 3v4.1l2.3 2.3 1.6-2.3 6.9 1.6 2.1-1.2 9 1.7 2.8-3.3 1.8-.6 1.2 1.8 1.6 4.1.9.1-8.5 54.8-47.9-8.2z',
	},
	{
		title: 'Illinois',
		abbreviation: 'il',
		svgPath:
			'm623.5 265.9-1 5.2v2l2.4 3.5v.7l-.3.9.9 1.9-.3 2.4-1.6 1.8-1.3 4.2-3.8 5.3-.1 7h-1l.9 1.9v.9l-2.2 2.7.1 1.1 1.5 2.2-.1.9-3.7.6-.6 1.2-1.2-.6-1 .5-.4 3.3 1.7 1.8-.4 2.4-1.5.3-6.9-3-4 3.7.3 1.8h-2.8l-1.4-1.5-1.8-3.8v-1.9l.8-.6.1-1.3-1.7-1.9-.9-2.5-2.7-4.1-4.8-1.3-7.4-7.1-.4-2.4 2.8-7.6-.4-1.9 1.2-1.1v-1.3l-2.8-1.5-3-.7-3.4 1.2-1.3-2.3.6-1.9-.7-2.4-8.6-8.4-2.2-1.5-2.5-5.9-1.2-5.4 1.4-3.7.7-.7.1-2.3-.7-.9 1-1.5 1.8-.6.9-.3 1-1.2v-2.4l1.7-2.4.5-.5.1-3.5-.9-1.4-1-.3-1.1-1.6 1-4 3-.8h2.4l4.2-1.8 1.7-2.2.1-2.4 1.1-1.3 1.3-3.2-.1-2.6-2.8-3.5h-1.2l-.9-1.1.2-1.6-1.7-1.7-2.5-1.3.5-.6 45.9-2.8.1 4.6 3.4 4.6 1.2 4.1 1.6 3.2z',
	},
	{
		title: 'Indiana',
		abbreviation: 'in',
		svgPath:
			'm629.2 214.8-5.1 2.3-4.7-1.4 4.1 50.2-1 5.2v2l2.4 3.5v.7l-.3.9.9 1.9-.3 2.4-1.6 1.8-1.3 4.2-3.8 5.3-.1 7h-1l.9 1.9 1.1.8.6-1-.7-1.7 4.6-.5.2 1.2 1.1.2.4-.9-.6-1.3.3-.8 1.3.8 1.7-.4 1.7.6 3.4 2.1 1.8-2.8 3.5-2.2 3 3.3 1.6-2.1.3-2.7 3.8-2.3.2 1.3 1.9 1.2 3-.2 1.2-.7.1-3.4 2.5-3.7 4.6-4.4-.1-1.7 1.2-3.8 2.2 1 6.7-4.5-.4-1.7-1.5-2.1 1-1.9-6.6-57.2-.1-1.4-32.4 3.4z',
	},
	{
		title: 'Iowa',
		abbreviation: 'ia',
		svgPath:
			'm556.9 183 2.1 1.6.6 1.1-1.6 3.3-.1 2.5 2 5.5 2.7 1.5 3.3.7 1.3 2.8-.5.6 2.5 1.3 1.7 1.7-.2 1.6.9 1.1h1.2l2.8 3.5.1 2.6-1.3 3.2-1.1 1.3-.1 2.4-1.7 2.2-4.2 1.8h-2.4l-3 .8-1 4 1.1 1.6 1 .3.9 1.4-.1 3.5-.5.5-1.7 2.4v2.4l-1 1.2-.9.3-1.8.6-1 1.5.7.9-.1 2.3-.7.7-1.5-.8-1.1-1.1-.6-1.6-1.7-1.3-14.3.8-27.2 1.2-25.9-.1-1.8-4.4.7-2.2-.8-3.3.2-2.9-1.3-.7-.4-6.1-2.8-5-.2-3.7-2.2-4.3-1.3-3.7v-1.4l-.6-1.7v-2.3l-.5-.9-.7-1.7-.3-1.3-1.3-1.2 1-4.3 1.7-5.1-.7-2-1.3-.4-.4-1.6 1-.5.1-1.1-1.3-1.5.1-1.6 2.2.1h28.2l36.3-.9 18.6-.7z',
	},
	{
		title: 'Kansas',
		abbreviation: 'ks',
		svgPath:
			'm459.1 259.5-43.7-1.2-36-2-4.8 67 67.7 2.9 62 .1-.5-48.1-3.2-.7-2.6-4.7-2.5-2.5.5-2.3 2.7-2.6.1-1.2-1.5-2.1-.9 1-2-.6-2.9-3z',
	},
	{
		title: 'Kentucky',
		abbreviation: 'ky',
		svgPath:
			'm692.1 322.5-20.5 1.4-5.2.8-17.4 1-2.6.8-22.6 2-.7-.6h-3.7l1.2 3.2-.6.9-23.3 1.5 1-2.7 1.4.9.7-.4 1.2-4.1-1-1 1-2 .2-.9-1.3-.8-.3-1.8 4-3.7 6.9 3 1.5-.3.4-2.4-1.7-1.8.4-3.3 1-.5 1.2.6.6-1.2 3.7-.6.1-.9-1.5-2.2-.1-1.1 2.2-2.7v-.9l1.1.8.6-1-.7-1.7 4.6-.5.2 1.2 1.1.2.4-.9-.6-1.3.3-.8 1.3.8 1.7-.4 1.7.6 3.4 2.1 1.8-2.8 3.5-2.2 3 3.3 1.6-2.1.3-2.7 3.8-2.3.2 1.3 1.9 1.2 3-.2 1.2-.7.1-3.4 2.5-3.7 4.6-4.4-.1-1.7 1.2-3.8 2.2 1 6.7-4.5-.4-1.7-1.5-2.1 1-1.9 1.3.5 2.2.1 1.9-.8 2.9 1.2 2.2 3.4v1l4.1.7 2.3-.2 1.9 2.1 2.2.2v-1l1.9-.8 3 .8 1.2.8 1.3-.7h.9l.6-1.7 3.4-1.8.5.8.8 2.9 3.5 1.4 1.2 2.1-.1 1.1.6 1-.6 3.6 1.9 1.6.8 1.1 1 .6-.1.9 4.4 5.6h1.4l1.5 1.8 1.2.3 1.4-.1-4.9 6.6-2.9 1-3 3-.4 2.2-2.1 1.3-.1 1.7-1.4 1.4-1.8.5-.5 1.9-1 .4-6.9 4.2zm-98 11.3-.7-.7.2-1h1.1l.7.7-.3 1z',
	},
	{
		title: 'Louisiana',
		abbreviation: 'la',
		svgPath:
			'm602.5 472.8-1.2-1.8.3-1.3-4.8-6.8.9-4.6 1-1.4.1-1.4-36 2 1.7-11.9 2.4-4.8 6-8.4-1.8-2.5h2v-3.3l-2.4-2.5.5-1.7-1.2-1-1.6-7.1.6-1.4-52.3 1.3.5 19.9.7 3.4 2.6 2.8.7 5.4 3.8 4.6.8 4.3h1l-.1 7.3-3.3 6.4 1.3 2.3-1.3 1.5.7 3-.1 4.3-2.2 3.5-.1.8-1.7 1.2 1 1.8 1.2 1.1 1.6-1.3 5.3-.9 6.1-.1 9.6 3.8 8 1 1.5-1.4 1.8-.2 4.8 2.2 1.6-.4 1.1-1.5-4.2-1.8-2.2 1-1.1-.2-1.4-2 3.3-2.2 1.6-.1v1.7l1.5-.1 3.4-.3.4 2.3 1.1.4.6 1.9 4.8 1 1.7 1.6v.7h-1.2l-1.5 1.7 1.7 1.2 5.4 1 2.7 2.8 4.4-1-3.7.2-.1-.6 2.8-.7.2-1.8 1.2-.3V494l1.1.1v1.6l2.5.1.8-1.9.9.3.2 2.5 1.2.2-1.8 2 2.6-.9 2-1.1 2.9-3.3h-.7l-1.3 1.2-.4-.1-.5-.8.9-1.2v-2.3l1.1-.8.7.7 1-.8 1-.1.6 1.3-.6 1.9h2.4l5.1 1.7.5 1.3 1.6 1.4 2.8.1 1.3.7 1.8-1 .9-1.7v-1.7h-1.4L613 492l-1.1-1.1-3.2-.9-2.6.2-4.2-2.4v-2.3l1.3-1 2.4.6-3.1-1.6.2-.8h3.6l2.6-3.5-2.6-1.8.8-1.5-1.2-.8h-.8l-2 2.1v2.1l-.6.7-1.1-.1-1.6-1.4h-1.3V477l.6-.7.8.7 1.7-1.6.7-1.6.8-.3zm-10.3-2.7 1.9 1 .8 1.1 2.5.1 1.5.8.2 1.4-.4.6-.9-1.5-1.4 1.2-.9 1.4-2.8.8-1.6.1-3.7-1 .1-1.7 2-2 1.1-2.4zm-4.7 1.2v1.1l-1.8 2h-1.2v-2.2l1.6-1.5z',
	},
	{
		title: 'Maine',
		abbreviation: 'me',
		svgPath:
			'm875 128.7.6 4 3.2 2 .8 2.2 2.3 1.4 1.4-.3 1-3-.8-2.9 1.6-.9.5-2.8-.6-1.3 3.3-1.9-2.2-2.3.9-2.4 1.4-2.2.5 3.2 1.6-2 1.3.9 1.2-.8v-1.7l3.2-1.3.3-2.9 2.5-.2 2.7-3.7v-.7l-.9-.5-.1-3.3.6-1.1.2 1.6 1-.5-.2-3.2-.9.3-.1 1.2-1.2-1.4.9-1.4.6.1 1.1-.4.5 2.8 2-.3 2.9.7v-1l-1.1-1.2 1.3.1.1-2.3.6.8.3 1.9 2.1 1.5.2-1 .9-.2-.3-.8.8-.6-.1-1.6-1.6-.2-2 .7 1.4-1.6.7-.8 1.3-.2.4 1.3 1.7 1.6.4-2.1 2.3-1.2-.9-1.3.1-1.7 1.1.5h.7l1.7-1.4.4-2.3 2.2.3.1-.7.2-1.6.5 1.4 1.5-1 2.3-4.1-.1-2.2-1.4-2-3-3.2H921l-.8 2.2-2.9-3 .3-.8v-1.5l-1.6-4.5-.8-.2-.7.4h-4.8l-.3-3.6-8.1-26-7.3-3.7-2.9-.1-6.7 6.6-2.7-1-1-3.9H878l-6.9 19.5.7 6.2-1.7 2.4-.4 4.6 1.3 3.7.8.2v1.6l-1.6 4.5-1.5 1.4-1.3 2.2-.4 7.8-2.4-1-1.5.4zm34.6-24.7-1 .8v1.3l.7-.8.9.8.4-.5 1.1.2-1-.8.4-.8zm-1.7 2.6-1 1.1.5.4-.1 1h1.1v-1.8zm-3-1.6.9 1.3 1 .5.3-1V104l-1.3-.7-.4 1.2zm-1 5-1.7-1.7 1.6-2.4.8.3.2 1.1 1 .8v1.1l-1 1z',
	},
	{
		title: 'Maryland',
		abbreviation: 'md',
		svgPath:
			'M822.9 269.3v-1.7h-.8v1.8zm11.8-3.9 1.2-2.2.1-2.5-.6-.6-.7.9-.2 2.1-.8 1.4-.3 1.1-4.6 1.6-.7.8-1.3.2-.4.9-1.3.6-.3-2.5.4-.7-.8-.5.2-1.5-1.6 1v-2l1.2-.3-1.9-.4-.7-.8.4-1.3-.8-.6-.7 1.6.5.8-.7.6-1.1.5-2-1-.2-1.2-1-1.1-1.4-1.7 1.5-.8-1-.6v-.9l.6-1 1.7-.3-1.4-.6-.1-.7-1.3-.1-.4 1.1-.6.3.1-3.4 1-1 .8.7.1-1.6-1-.9-.9 1.1-1 1.4-.6-1 .2-2.4.9-1 .9.9 1.2-.7-.4-1.7-1 1-.9-2.1-.2-1.7 1.1-2.4 1.1-1.4 1.4-.2-.5-.8.5-.6-.3-.7.2-2.1-1.5.4-.8 1.1 1 1.3-2.6 3.6-.9-.4-.7.9-.6 2.2-1.8.5 1.3.6 1.3 1.3-.2.7.9 1.2-1.1 1 .5.3-.5 1.3v2.1l-.5 1.3.9 1.1.7 3.4 1.3 1.4 1.6 1.4.4 2.8 1.6 2 .4 1.4v1h-.7l-1.5-1.2-.4.2-1.2-.2-1.7-1.4-1.4-.3-1 .5-1.2-.3-.4.2-1.7-.8-1-1-1-1.3-.6-.2-.8.7-1.6 1.3-1.1-.8-.4-2.3.8-2.1-.3-.5.3-.4-.7-1 1-.1 1-.9.4-1.8 1.7-2.6-2.6-1.8-1 1.7-.6-.6h-1l-.6-.1-.4-.4.1-.5-1.7-.6-.8.3-1.2-.1-.7-.7-.5-.2-.2-.7.6-.8v-.9l-1.2-.2-1-.9-.9.1-1.6-.3-.9-.4.2-1.6-1-.5-.2-.7h-.7l-.8-1.2.2-1-2.6.4-2.2-1.6-1.4.3-.9 1.4h-1.3l-1.7 2.9-3.3.4-1.9-1-2.6 3.8-2.2-.3-3.1 3.9-.9 1.6-1.8 1.6-1.7-11.4 60.5-11.8 7.6 27.1 10.9-2.3v5.3l-.1 3.1-1 1.8zm-13.4-1.8-1.3.9.8 1.8 1.7.8-.4-1.6z',
	},
	{
		title: 'Massachusetts',
		abbreviation: 'ma',
		svgPath:
			'M899.9 174.2h3.4l.9-.6.1-1.3-1.9-1.8.4 1-1.5 1.5H899l.1.8zm-9 1.8-1.2-.6 1-.8.6-2.1 1.2-1 .8-.2.6.9 1.1.2.6-.6.5 1.9-1.3.3-2.8.7zM856 152.6l18.4-3.8 1-1.5.3-1.7 1.9-.6.5-1.1 1.7-1.1 1.3.3 1.7 3.3 1 .4 1.1-1.3.8 1.3v1.1l-3 2.4.2.8-.9 1 .4.8-1.3.3.9 1.2-.8.7.6 1 .9-.2.3-.8 1.1.6h1.8l2.5 2.6.2 2.6 1.8.1.8 1.1.6 2 1 .7h1.9l1.9-.1.8-.9 1.6-1.2 1.1-.3-1.2-2.1-.3.9-1.5-3.6h-.8l-.4.9-1.2-1 1.3-1.1 1.8.4 2.3 2.1 1.3 2.7 1.2 3.3-1 2.8v-1.8l-.7-1-3.5 2.3-.9-.3-1.6 1-.1 1.2-2.2 1.2-2 2.1-2 1.9h-1.2l3.3-3.3.5-1.9-.5-.6-.3-1.3-.9-.1-.1 1.3-1 1.2h-1.2l-.3 1.1.4 1.2-1.2 1.1-1.1-.2-.4 1-1.4-3-1.3-1.1-2.6-1.3-.6-2.2h-.8l-.7-2.6-6.5 2-.1-.3-14.9 3.4v.7l-.9.3-.5-.7-10.5 2.4-.7-1 .5-15z',
	},
	{
		title: 'Michigan',
		abbreviation: 'mi',
		svgPath:
			'm663.3 209.8.1 1.4 21.4-3.5.5-1.2 3.9-5.9v-4.3l.8-2.1 2.2-.8 2-7.8 1-.5 1 .6-.2.6-1.1.8.3.9.8.4 1.9-1.4.4-9.8-1.6-2.3-1.2-3.7v-2.5l-2.3-4.4v-1.8l-1.2-3.3-2.3-3-2.9-1-4.8 3-2.5 4.6-.2.9-3 3.5-1.5-.2-2.9-2.8-.1-3.4 1.5-1.9 2-.2 1.2-1.7.2-4 .8-.8 1.1-.1.9-1.7-.2-9.6-.3-1.3-1.2-1.2-1.7-1-.1-1.8.7-.6 1.8.8-.3-1.7-1.9-2.7-.7-1.6-1.1-1.1h-2.2l-8.1-2.9-1.4-1.7-3.1-.3-1.2.3-4.4-2.3h-1.4l.5 1-2.7-.1.1.6.6.6-2.5 2.1.1 1.8 1.5 2.3 1.5.2v.6l-1.5.5-2.1-.1-2.8 2.5.1 2.5.4 5.8-2.2 3.4.8-4.5-.8-.6-.9 5.3-1-2.3.5-2.3-.5-1 .6-1.3-.6-1.1 1-1v-1.2l-1.3.6-1.3 3.1-.7.7-1.3 2.4-1.7-.2-.1 1.2h-1.6l.2 1.5.2 2-3 1.2.1 1.3 1 1.7-.1 5.2-1.3 4.4-1.7 2.5 1.2 1.4.8 3.5-1 2.5-.2 2.1 1.7 3.4 2.5 4.9 1.2 1.9 1.6 6.9-.1 8.8-.9 3.9-2 3.2-.9 3.7-2 3-1.2 1zM567.5 113l3 3.8 17 3.8 1.4 1 4 .8.7.5 2.8-.2 4.9.8 1.4 1.5-1 1 .8.8 3.8.7 1.2 1.2.1 4.4-1.3 2.8 2 .1 1-.8.9.8-1.1 3.1 1 1.6 1.2.3.8-1.8 2.9-4.6 1.6-6 2.3-2-.5-1.6.5-.9 1 1.6-.3 2.2 2.9-2.2.2-2.3 2.1.6.8-1.6.7.6-.7 1.5-1 .5-1 2 1.4 1.8 1.1-.5-.5-.7 1-1.5 1.9-1.7h.8l.2-2.6 2-1.8 7.9-.5 1.9-3.1 3.8-.3 3.8 1.2 4.2 2.7.7-.2-.2-3.5.7-.2 4.5 1.1 1.5-.2 2.9-.7 1.7.4 1.8.1v-1.1l-.7-.9-1.5-.2-1.1-.8.5-1.4-.8-.3-2.6.1-.1-1 1.1-.8.6.8.5-1.8-.7-.7.7-.2-1.4-1.3.3-1.3.1-1.9h-1.3l-1.5 1-1.9.1-.5 1.8-1.9.2-.3-1.2-2.2.1-1 1.2-.7-.1-.2-.8-2.6.4-.1-4.8 1-2-.7-.1-1.8 1.1h-2.2l-3.8 2.7-6.2.3-4.1.8-1.9 1.5-1.4 1.3-2.5 1.7-.3.8-.6-1.7-1.3-.6v.6l.7.7v1.3l-1.5-.6h-.6l-.3 1.2-2-1.9-1.3-.2-1.3 1.5-3.2-.1-.5-1.4-2-1.9-1.3-1.6v-.7l-1.1-1.4-2.6-1.2-3.3-.1-1.1-.9h-1.4l-.7.4-2.2 2.2-.7 1.1-1-.7.2-1 .8-2.1 3.2-5 .8-.2 1.7-1.9.7-1.6 3-.6.8-.6-.1-1-.5-.5-4.5.2-2 .5-2.6 1.2-1.2 1.2-1.7 2.2-1.8 1-3.3 3.4-.4 1.6-7.4 4.6-4 .5-1.8.4-2.3 3-1.8.7-4.4 2.3zm100.7 3.8 3.8.1.6-.5-.2-2-1.7-1.8-1.9.1-.1.5 1.1.4-1.6.8-.3 1-.6-.6-.4.8zm-75.1-41.9-2.3.2-2.7 1.9-7.1 5.3.8 1 1.8.3 2.8-2-1.1-.5 2.3-1.6h1l3-1.9-.1-.9zm41.1 62.8v1l2.1 1.6-.2-2.4zm-.7 2.8 1.1.1v.9h-1zm21.4-21.3v.9l.8-.2v-.5zm4.7 3.1-.1-1.1-1.6-.2-.6-.4h-.9l-.4.3.9.4 1.1 1.1zm-18 1.2-.1 1.1-.3.7.2 2.2.4.3.7.1.5-.9.1-1.6-.3-.6-.1-1.1z',
	},
	{
		title: 'Minnesota',
		abbreviation: 'mn',
		svgPath:
			'm464.7 68.6-1.1 2.8.8 1.4-.3 5.1-.5 1.1 2.7 9.1 1.3 2.5.7 14 1 2.7-.4 5.8 2.9 7.4.3 5.8-.1 2.1-.1 2.2-.9 2-3.1 1.9-.3 1.2 1.7 2.5.4 1.8 2.6.6 1.5 1.9-.2 39.5h28.2l36.3-.9 18.6-.7-1.1-4.5-.2-3-2.2-3-2.8-.7-5.2-3.6-.6-3.3-6.3-3.1-.2-1.3h-3.3l-2.2-2.6-2-1.3.7-5.1-.9-1.6.5-5.4 1-1.8-.3-2.7-1.2-1.3-1.8-.3v-1.7l2.8-5.8 5.9-3.9-.4-13 .9.4.6-.5.1-1.1.9-.6 1.4 1.2.7-.1-1.2-2.2 4.3-3.1 3.1-3.7 1.6-.8L554 95l6.3-5.8 3.9-2.1 6.3-2.7 7.6-4.5-.6-.4-3.7.7-2.8.1-1-1.6-1.4-.9-9.8 1.2-1-2.8-1.6-.1-1.7.8-3.7 3.1h-4.1l-2.1-1-.3-1.7-3.9-.8-.6-1.6-.7-1.3-1 .9-2.6.1-9.9-5.5h-2.9l-.8-.7-3.1 1.3-.8 1.3-3.3.8-1.3-.2v-1.7l-.7-.9h-5.9l-.4-1.4h-2.6l-1.1.4-2.4-1.7.3-1.4-.6-2.4-.7-1.1-.2-3-1-3.1-2.1-1.6h-2.9l.1 8-30.9-.4z',
	},
	{
		title: 'Mississippi',
		abbreviation: 'ms',
		svgPath:
			'm623.8 468.6-5 .1-2.4-1.5-7.9 2.5-.9-.7-.5.2-.1 1.6-.6.1-2.6 2.7-.7-.1-.6-.7-1.2-1.8.3-1.3-4.8-6.8.9-4.6 1-1.4.1-1.4-36 2 1.7-11.9 2.4-4.8 6-8.4-1.8-2.5h2v-3.3l-2.4-2.5.5-1.7-1.2-1-1.6-7.1.6-1.4 1.2-1.5.5-3-1.5-2.3-.5-2.2.9-.7v-.8l-1.7-1.1-.1-.7 1.6-.9-1.2-1.1 1.7-7.1 3.4-1.6v-.8l-1.1-1.4 2.9-5.4h1.9l1.5-1.2-.3-5.2 3.1-4.5 1.8-.6-.5-3.1 38.3-2.6 1.3 2-1.3 67 4.4 33.2z',
	},
	{
		title: 'Missouri',
		abbreviation: 'mo',
		svgPath:
			'm555.3 248.9-1.1-1.1-.6-1.6-1.7-1.3-14.3.8-27.2 1.2-25.9-.1 1.3 1.3-.3 1.4 2.1 3.7 3.9 6.3 2.9 3 2 .6.9-1 1.5 2.1-.1 1.2-2.7 2.6-.5 2.3 2.5 2.5 2.6 4.7 3.2.7.5 48.1.2 10.8 39.1-.7 39.8-2 1.6 2.5v2.2l-1.7 1.5-2.8 5.1 11.2-.8 1-2 1.2-.5v-.7l-1.2-1.1-.6-1 1.7.2.8-.7-1.4-1.5 1.4-.5.1-1-.6-1v-1.3l-.7-.7.2-1h1.1l.7.7-.3 1 .8.7.8-1 1-2.7 1.4.9.7-.4 1.2-4.1-1-1 1-2 .2-.9-1.3-.8h-2.8l-1.4-1.5-1.8-3.8v-1.9l.8-.6.1-1.3-1.7-1.9-.9-2.5-2.7-4.1-4.8-1.3-7.4-7.1-.4-2.4 2.8-7.6-.4-1.9 1.2-1.1v-1.3l-2.8-1.5-3-.7-3.4 1.2-1.3-2.3.6-1.9-.7-2.4-8.6-8.4-2.2-1.5-2.5-5.9-1.2-5.4 1.4-3.7z',
	},
	{
		title: 'Montana',
		abbreviation: 'mt',
		svgPath:
			'm247 130.5 57.3 7.9 51 5.3 2-20.7 5.2-66.7-53.5-5.6-54.3-7.7-65.9-12.5-4.8 22 3.7 7.4-1.6 4.8 3.6 4.8 1.9.7 3.9 8.3v2.1l2.3 3h.9l1.4 2.1h3.2v1.6l-7.1 17-.5 4.1 1.4.5 1.6 2.6 2.8-1.4 3.6-2.4 1.9 1.9.5 2.5-.5 3.2 2.5 9.7 2.6 3.5 2.3 1.4.4 3v4.1l2.3 2.3 1.6-2.3 6.9 1.6 2.1-1.2 9 1.7 2.8-3.3 1.8-.6 1.2 1.8 1.6 4.1.9.1z',
	},
	{
		title: 'Nebraska',
		abbreviation: 'ne',
		svgPath:
			'm402.5 191.1 38 1.6 3.4 3.2 1.7.2 2.1 2 1.8-.1 1.8-2 1.5.6 1-.7.7.5.9-.4.7.4.9-.4 1 .5 1.4-.6 2 .6.6 1.1 6.1 2.2 1.2 1.3.9 2.6 1.8.7 1.5-.2.5.9v2.3l.6 1.7v1.4l1.3 3.7 2.2 4.3.2 3.7 2.8 5 .4 6.1 1.3.7-.2 2.9.8 3.3-.7 2.2 1.8 4.4 1.3 1.3-.3 1.4 2.1 3.7 3.9 6.3h-32.4l-43.7-1.2-36-2 1.4-22.1-33.1-2.4 3.7-44.2z',
	},
	{
		title: 'Nevada',
		abbreviation: 'nv',
		svgPath:
			'm167.6 296.8-3.4 17.5-2.4 2.9h-2l-1.2-2.7-3.7-1.4-3.5.6-1 13.6.5 4.9-.5 2.9-1.4 3-70.4-105-1.1-3.5 16.4-63.1 47 11.2 24.4 5.4 23.3 4.7z',
	},
	{
		title: 'New Hampshire',
		abbreviation: 'nh',
		svgPath:
			'm862.6 93.6-1.3.1-1-1.1-1.9 1.4-.5 6.1 1.2 2.3-1.1 3.5 2.1 2.8-.4 1.7.1 1.3-1.1 2.1-1.4.4-.6 1.3-2.1 1-.7 1.5 1.4 3.4-.5 2.5.5 1.5-1 1.9.4 1.9-1.3 1.9.2 2.2-.7 1.1.7 4.5.7 1.5-.5 2.6.9 1.8-.2 2.5-.5 1.3-.1 1.4 2.1 2.6 18.4-3.8 1-1.5.3-1.7 1.9-.6.5-1.1 1.7-1.1 1.3.3.8-4.8-2.3-1.4-.8-2.2-3.2-2-.6-4-11.9-36.8z',
	},
	{
		title: 'New Jersey',
		abbreviation: 'nj',
		svgPath:
			'm842.5 195.4-14.6-4.9-1.8 2.5.1 2.2-3 5.4 1.5 1.8-.7 2-1 1 .5 3.6 2.7.9 1 2.8 2.1 1.1 4.2 3.2-3.3 2.6-1.6 2.3-1.8 3-1.6.6-1.4 1.7-1 2.2-.3 2.1.8.9.4 2.3 1.2.6 2.4 1.5 1.8.8 1.6.8.1 1.1.8.1 1.1-1.2.8.4 2.1.2-.2 2.9.2 2.5 1.8-.7 1.5-3.9 1.6-4.8 2.9-2.8.6-3.5-.6-1.2 1.7-2.9v-1.2l-.7-1.1 1.2-2.7-.3-3.6-.6-8.2-1.2-1.4v1.4l.5.6h-1.1l-.6-.4-1.3-.2-.9.6-1.2-1.6.7-1.7v-1l1.7-.7.8-2.1z',
	},
	{
		title: 'New Mexico',
		abbreviation: 'nm',
		svgPath:
			'M357.5 332.9h-.8l-7.9 99.3-31.8-2.6-34.4-3.6-.3 3 2 2.2-30.8-4.1-1.4 10.2-15.7-2.2L253.8 311l52.6 6.5 51.7 4.8z',
	},
	{
		title: 'New York',
		abbreviation: 'ny',
		svgPath:
			'm872.9 181.6-1.3.1-.5 1zm-30.6 22.7.7.6 1.3-.3 1.1.3.9-1.3h1.9l2.4-.9 5.1-2.1-.5-.5-1.9.8-2 .9.2-.8 2.6-1.1.8-1 1.2.1 4.1-2.3v.7l-4.2 3 4.5-2.8 1.7-2.2 1.5-.1 4.5-3.1 3.2-3.1 3-2.3 1-1.2-1.7-.1-1 1.2-.2.7-.9.7-.8-1.1-1.7 1-.1.9-.9-.2.5-.9-1.2-.7-.6.9.9.3.2.5-.3.5-1.4 2.6h-1.9l.9-1.8.9-.6.3-1.7 1.4-1.6.9-.8 1.5-.7-1.2-.2-.7.9h-.7l-1.1.8-.2 1-2.2 2.1-.4.9-1.4.9-7.7 1.9.2.9-.9.7-2 .3-1-.6-.2 1.1-1.1-.4.1 1-1.2-.1-1.2.5-.2 1.1h-1l.2 1h-.7l.2 1-1.8.4-1.5 2.3zm-.8-.4-1.6.4v1l-.7 1.6.6.7 2.4-2.3-.1-.9zm-10.1-95.2-.6 1.9 1.4.9-.4 1.5.5 3.2 2.2 2.3-.4 2.2.6 2-.4 1-.3 3.8 3.1 6.7-.8 1.8.9 2.2.9-1.6 1.9 1.5 3 14.2-.5 2 1.1 1-.5 15 .7 1 2.8 16.3 1.8 1.5-3.5 3.4 1.7 2.2-1.3 3.3-1.5 1.7-1.5 2.3-.2-.7.4-5.9-14.6-4.9-1.6-1.1-1.9.3-3-2.2-3-5.8h-2l-.4-1.5-1.7-1.1-70.5 13.9-.8-6 4.3-3.9.6-1.7 3.9-2.5.6-2.4 2.3-2 .8-1.1-1.7-3.3-1.7-.5-1.8-3-.2-3.2 7.6-3.9 8.2-1.6h4.4l3.2 1.6.9-.1 1.8-1.6 3.4-.7h3l2.6-1.3 2.5-2.6 2.4-3.1 1.9-.4 1.1-.5.4-3.2-1.4-2.7-1.2-.7 2-1.3-.1-1.8h-1.5l-2.3-1.4-.1-3.1 6.2-6.1.7-2.4 3.7-6.3 5.9-6.4 2.1-1.7 2.5.1 20.6-5.2z',
	},
	{
		title: 'North Carolina',
		abbreviation: 'nc',
		svgPath:
			'm829 300.1-29.1 6.1-39.4 7.3-29.4 3.5v5.2l-1.5-.1-1.4 1.2-2.4 5.2-2.6-1.1-3.5 2.5-.7 2.1-1.5 1.2-.8-.8-.1-1.5-.8-.2-4 3.3-.6 3.4-4.7 2.4-.5 1.2-3.2 2.6-3.6.5-4.6 3-.8 4.1-1.3.9-1.5-.1-1.4 1.3-.1 4.9 21.4-3 4.4-1.9 1.3-.1 7.3-4.3 23.2-2.2.4.5-.2 1.4.7.3 1.2-1.5 3.3 3 .1 2.6 19.7-2.8 24.5 17.1 4-2.2 3-.7h1.7l1.1 1.1.8-2 .6-5 1.7-3.9 5.4-6.1 4.1-3.5 5.4-2.3 2.5-.4 1.3.4.7 1.1 3.3-6.6 3.3-5.3-.7-.3-4.4 6.8-.5-.8 2-2.2-.4-1.5-2-.5 1 1.3-1.2.1-1.2-1.8-1.2 2-1.6.2 1-2.7.7-1.7-.2-2.9-2.2-.1.9-.9 1.1.3 2.7.1.8-.5h2.3l2-1.9.2-3.2 1.3-1.4 1.2-.2 1.3-1-.5-3.7-2.2-3.8-2.7-.2-.9 1.6-.5-1-2.7.2-1.2.4-1.9 1.2-.3-.4h-.9l-1.8 1.2-2.6.5v-1.3l.8-1 1 .7h1l1.7-2.1 3.7-1.7 2-2.2h2.4l.8 1.3 1.7.8-.5-1.5-.3-1.6-2.8-3.1-.3-1.4-.4 1-.9-1.3zm7 31 2.7-2.5 4.6-3.3v-3.7l-.4-3.1-1.7-4.2 1.5 1.4 1 3.2.4 7.6-1.7.4-3.1 2.4-3.2 3.2zm1.9-19.3-.9-.2v1l2.5 2.2-.2-1.4zm2.9 2.1-1.4-2.8-2.2-3.4-2.4-3-2.2-4.3-.8-.7 2.2 4.3.3 1.3 3.4 5.5 1.8 2.1z',
	},
	{
		title: 'North Dakota',
		abbreviation: 'nd',
		svgPath:
			'm464.7 68.6-1.1 2.8.8 1.4-.3 5.1-.5 1.1 2.7 9.1 1.3 2.5.7 14 1 2.7-.4 5.8 2.9 7.4.3 5.8-.1 2.1-29.5-.4-46-2.1-39.2-2.9 5.2-66.7 44.5 3.4 55.3 1.6z',
	},
	{
		title: 'Ohio',
		abbreviation: 'oh',
		svgPath:
			'm685.7 208.8 1.9-.4 3 1.3 2.1.6.7.9h1l1-1.5 1.3.8h1.5l-.1 1-3.1.5-2 1.1 1.9.8 1.6-1.5 2.4-.4 2.2 1.5 1.5-.1 2.5-1.7 3.6-2.1 5.2-.3 4.9-5.9 3.8-3.1 9.3-5.1 4.9 29.9-2.2 1.2 1.4 2.1-.1 2.2.6 2-1.1 3.4-.1 5.4-1 3.6.5 1.1-.4 2.2-1.1.5-2 3.3-1.8 2h-.6l-1.8 1.7-1.3-1.2-1.5 1.8-.3 1.2h-1.3l-1.3 2.2.1 2.1-1 .5 1.4 1.1v1.9l-1 .2-.7.8-1 .5-.6-2.1-1.6-.5-1 2.3-.3 2.2-1.1 1.3 1.3 3.6-1.5.8-.4 3.5h-1.5l-3.2 1.4-1.2-2.1-3.5-1.4-.8-2.9-.5-.8-3.4 1.8-.6 1.7h-.9l-1.3.7-1.2-.8-3-.8-1.9.8v1l-2.2-.2-1.9-2.1-2.3.2-4.1-.7v-1l-2.2-3.4-2.9-1.2-1.9.8-2.2-.1-1.3-.5-6.6-57.2 21.4-3.5z',
	},
	{
		title: 'Oklahoma',
		abbreviation: 'ok',
		svgPath:
			'm501.5 398.6-4.6-3.8-2.2-.9-.5 1.6-5.1.3-.6-1.5-5 2.5-1.6-.7-3.7.3-.6 1.7-3.6.9-1.3-1.2-1.2.1-2-1.8-2.1.7-2-.5-1.8-2-2.5 4.2-1.2.8-1-1.8.3-2-1.2-.7-2.3 2.5-1.7-1.2-.1-1.5-1.3.5-2.6-1.7-3 2.6-2.3-1.1.7-2.1-2.3.1-1.9-3-3.5-1.1-2 2.3-2.3-2.2-1.4.4-2 .1-3.5-1.9-2.3.1-1.2-.7-.5-2.9-2.3-1.7-1.1 1.5-1.4-1-1.2-.4-1.1 1-1.5-.3-2.5-3-2.7-1.3 1.4-42.7-52.6-3.2.6-10.6 16.5 1 67.7 2.9 62 .1.2 10.8 4.1 24.4-.7 39z',
	},
	{
		title: 'Oregon',
		abbreviation: 'or',
		svgPath:
			'm93.9 166.5 47 11.2 8.5-37.3 2.9-5.8.4-2.1.8-.9-.9-2-2.9-1.2.2-4.2 4-5.8 2.5-.8 1.6-2.3-.1-1.6 1.8-1.6 3.2-5.5 4.2-4.8-.5-3.2-3.5-3.1-1.6-3.6-30.3-7.3-2.8 1-5.4-.9-1.8-.9-1.5 1.2-3.3-.4-4.5.5-.9.7-4.2-.4-.8-1.6-1.2-.2-4.4 1.3-1.6-1.1-2.2.8-.2-1.8-2.3-1.2-1.5-.2-1-1.1-3 .3-1.2-.8h-1.2l-1.2.9-5.5.7-6.6-4.2 1.1-5.6-.4-4.1-3.2-3.7-3.7.1-.4-1.1.4-1.2-.7-.8-1 .1-1.1 1.3-1.5-.2-.5-1.1-1-.1-.7.6-2-1.9v4.3l-1.3 1.3-1.1 3.5-.1 2.3-4.5 12.3L38 114.5l-3.2 4.6-1.6-.1.1 2.1-5.2 7.1-.3 3.3 1 1.3.1 2.4-1.2 1.1-1.2 3 .1 5.7 1.2 2.9z',
	},
	{
		title: 'Pennsylvania',
		abbreviation: 'pa',
		svgPath:
			'm826.3 189.4-1.9.3-3-2.2-3-5.8h-2l-.4-1.5-1.7-1.1-70.5 13.9-.8-6-4.2 3.4-.9.1-2.7 3-3.3 1.7 4.9 29.9 3.2 19.7 17.4-2.9 60.5-11.8 1.2-2.1 1.5-1.1 1.6-.3 1.6.6 1.4-1.7 1.6-.6 1.8-3 1.6-2.3 3.3-2.6-4.2-3.2-2.1-1.1-1-2.8-2.7-.9-.5-3.6 1-1 .7-2-1.5-1.8 3-5.4-.1-2.2 1.8-2.5z',
	},
	{
		title: 'Rhode Island',
		abbreviation: 'ri',
		svgPath:
			'm883.2 170.7-1.3-1.1-2.6-1.3-.6-2.2h-.8l-.7-2.6-6.5 2 3.2 12.3-.4 1.1.4 1.8 5.6-3.6.1-3-.8-.8.4-.6-.1-1.3-.9-.7 1.2-.4-.9-1.6 1.8.7.3 1.4.7 1.2-1.4-.8 1.1 1.7-.3 1.2-.6-1.1v2.5l.6-.9.4.9 1.3-1.5-.2-2.5 1.4 3.1 1-.9zm-4.7 12.2h.9l.5-.6-.8-1.3-.7.7z',
	},
	{
		title: 'South Carolina',
		abbreviation: 'sc',
		svgPath:
			'm772.3 350.2-19.7 2.8-.1-2.6-3.3-3-1.2 1.5-.7-.3.2-1.4-.4-.5-23.2 2.2-7.3 4.3-1.3.1-4.4 1.9-.1 1.9-1.9 1-1.4 3.2.2 1.3 6.1 3.8 2.6-.3 3.1 4 .4 1.7 4.2 5.1 2.6 1.7 1.4.2 2.2 1.6 1.1 2.2 2 1.6 1.8.5 2.7 2.7.1 1.4 2.6 2.8 5 2.3 3.6 6.7.3 2.7 3.9 2.1 2.5 4.8.8 3.1 4.2.4.8-1.5h.6l1.8-1.5.5-2 3.2-2.1.3-2.4-1.2-.9.8-.7.8.4 1.3-.4 1.8-2.1 3.8-1.8 1.6-2.4.1-.7 4.8-4.4-.1-.5-.9-.8 1.1-1.5h.8l.4.5.7-.8h1.3l.6-1.5 2.3-2.1-.3-5.4.8-2.3 3.6-6.2 2.4-2.2 2.2-1.1z',
	},
	{
		title: 'South Dakota',
		abbreviation: 'sd',
		svgPath:
			'm396.5 125.9 46 2.1 29.5.4-.1 2.2-.9 2-3.1 1.9-.3 1.2 1.7 2.5.4 1.8 2.6.6 1.5 1.9-.2 39.5-2.2-.1-.1 1.6 1.3 1.5-.1 1.1-1 .5.4 1.6 1.3.4.7 2-1.7 5.1-1 4.3 1.3 1.2.3 1.3.7 1.7-1.5.2-1.8-.7-.9-2.6-1.2-1.3-6.1-2.2-.6-1.1-2-.6-1.4.6-1-.5-.9.4-.7-.4-.9.4-.7-.5-1 .7-1.5-.6-1.8 2-1.8.1-2.1-2-1.7-.2-3.4-3.2-38-1.6-51.1-3.5 3.9-43.9 2-20.7z',
	},
	{
		title: 'Tennessee',
		abbreviation: 'tn',
		svgPath:
			'm620.9 365.1 45.7-4 22.9-2.9.1-4.9 1.4-1.3 1.5.1 1.3-.9.8-4.1 4.6-3 3.6-.5 3.2-2.6.5-1.2 4.7-2.4.6-3.4 4-3.3.8.2.1 1.5.8.8 1.5-1.2.7-2.1 3.5-2.5 2.6 1.1 2.4-5.2 1.4-1.2 1.5.1V317l.3-.7-4.6.5-.2 1-28.9 3.3-5.6 1.4-20.5 1.4-5.2.8-17.4 1-2.6.8-22.6 2-.7-.6h-3.7l1.2 3.2-.6.9-23.3 1.5-.8 1-.8-.7h-1v1.3l.6 1-.1 1-1.4.5 1.4 1.5-.8.7-1.7-.2.6 1 1.2 1.1v.7l-1.2.5-1 2 .1.6 1.4 1-.4.7h-1.5v.5l.9.9.1.8-1.4.2-.5.8-1.6.2-.9.9.6.9 1.1-.1.5.9-1.6 1.3.4 1.5-2-.6-.1.7.4 1.1-.3 1.4-1.3-.8-.8.8 1.1.1.1 1.5-.6 1 1.1.9-.3 1.5.8.7-.7 1-1.2-.5-.9 2.2-1.6.7z',
	},
	{
		title: 'Texas',
		abbreviation: 'tx',
		svgPath:
			'm282.3 429 .3-3 34.4 3.6 31.8 2.6 7.9-99.3h.8l52.6 3.2-1.4 42.7 2.7 1.3 2.5 3 1.5.3 1.1-1 1.2.4 1.4 1 1.1-1.5 2.3 1.7.5 2.9 1.2.7 2.3-.1 3.5 1.9 2-.1 1.4-.4 2.3 2.2 2-2.3 3.5 1.1 1.9 3 2.3-.1-.7 2.1 2.3 1.1 3-2.6 2.6 1.7 1.3-.5.1 1.5 1.7 1.2 2.3-2.5 1.2.7-.3 2 1 1.8 1.2-.8 2.5-4.2 1.8 2 2 .5 2.1-.7 2 1.8 1.2-.1 1.3 1.2 3.6-.9.6-1.7 3.7-.3 1.6.7 5-2.5.6 1.5 5.1-.3.5-1.6 2.2.9 4.6 3.8 6.4 1.9 2.6 2.3 2.8-1.3 3.2.8.2 11.9.5 19.9.7 3.4 2.6 2.8.7 5.4 3.8 4.6.8 4.3h1l-.1 7.3-3.3 6.4 1.3 2.3-1.3 1.5.7 3-.1 4.3-2.2 3.5-.1.8-1.7 1.2 1 1.8 1.2 1.1-3.5.3-8.4 3.9-3.5 1.4-1.8 1.8-.7-.5 2.1-2.3 1.8-.7.5-.9-2.9-.1-.7-.8.8-2-.9-1.8h-.6l-2.4 1.3-1.9 2.6.3 1.7 3.3 3.4 1.3.3v.8l-2.3 1.6-4.9 4-4 3.9-3.2 1.4-5 3-3.7 2-4.5 1.9-4.1 2.5 3.2-3v-1.1l.6-.8-.2-1.8-1.5-.1-1.1 1.5-2.6 1.3-1.8-1.2-.3-1.7h-1.5l.8 2.2 1.4.7 1.2.9 1.8 1.6-.7.8-3.9 1.7-1.7.1-1.2-1.2-.5 2.1.5 1.1-2.7 2-1.5.2-.8.7-.4 1.7-1.8 3.3-1.6.7-1.6-.6-1.8 1.1.3 1.4 1.3.8 1 .8-1.8 3.5-.3 2.8-1 1.7-1.4 1-2.9.4 1.8.6 1.9-.6-.4 3.2-1.1-.1.2 1.2.3 1.4-1.3.9v3.1l1.6 1.4.6 3.1-.4 2.2-1 .4.4 1.5 1.1.4.8 1.7v2.6l1.1 2.1 2.2 2.6-.1.7-2.2-.2-1.6 1.4.2 1.4-.9-.3-1.4-.2-3.4-3.7-2.3-.6h-7.1l-2.8-.8-3.6-3-1.7-1-2.1.1-3.2-2.6-5.4-1.6v-1.3l-1.4-1.8-.9-4.7-1.1-1.7-1.7-1.4v-1.6l-1.4-.6.6-2.6-.3-2.2-1.3-1.4.7-3-.8-3.2-1.7-1.4h-1.1l-4-3.5.1-1.9-.8-1.7-.8-.2-.9-2.4-2-1.6-2.9-2.5-.2-2.1-1-.7.2-1.6.5-.7-1.4-1.5.1-.7-2-2.2.1-2.1-2.7-4.9-.1-1.7-1.8-3.1-5.1-4.8v-1.1l-3.3-1.7-.1-1.8-1.2-.4v-.7l-.8-.2-2.1-2.8h-.8l-.7-.6-1.3 1.1h-2.2l-2.6-1.1h-4.6l-4.2-2.1-1.3 1.9-2.2-.6-3.3 1.2-1.7 2.8-2 3.2-1.1 4.4-1.4 1.2-1.1.1-.9 1.6-1.3.6-.1 1.8-2.9.1-1.8-1.5h-1l-2-2.9-3.6-.5-1.7-2.3-1.3-.2-2.1-.8-3.4-3.4.2-.8-1.6-1.2-1-.1-3.4-3.1-.1-2-2.3-4 .2-1.6-.7-1.3.8-1.5-.1-2.4-2.6-4.1-.6-4.2-1.6-1.6v-1l-1.2-.2-.7-1.1-2.4-1.7-.9-.1-1.9-1.6v-1.1l-2.9-1.8-.6-2.1-2.6-2.3-3.2-4.4-3-1.3-2.1-1.8.2-1.2-1.3-1.4-1.7-3.7-2.4-1zm174.9 138.3.8.1-.6-4.8-3.5-12.3-.2-8.1 4.9-10.5 6.1-8.2 7.2-5.1v-.7h-.8l-2.6 1-3.6 2.3-.7 1.5-8.2 11.6-2.8 7.9v8.8l3.6 12z',
	},
	{ title: 'Utah', abbreviation: 'ut', svgPath: 'm233.2 217.9 3.3-21.9-47.9-8.2-21 109 46.2 8.2 40 6 11.5-88.3z' },
	{
		title: 'Vermont',
		abbreviation: 'vt',
		svgPath:
			'm859.1 102.4-1.1 3.5 2.1 2.8-.4 1.7.1 1.3-1.1 2.1-1.4.4-.6 1.3-2.1 1-.7 1.5 1.4 3.4-.5 2.5.5 1.5-1 1.9.4 1.9-1.3 1.9.2 2.2-.7 1.1.7 4.5.7 1.5-.5 2.6.9 1.8-.2 2.5-.5 1.3-.1 1.4 2.1 2.6-12.4 2.7-1.1-1 .5-2-3-14.2-1.9-1.5-.9 1.6-.9-2.2.8-1.8-3.1-6.7.3-3.8.4-1-.6-2 .4-2.2-2.2-2.3-.5-3.2.4-1.5-1.4-.9.6-1.9-.8-1.7 27.3-6.9z',
	},
	{
		title: 'Virginia',
		abbreviation: 'va',
		svgPath:
			'm834.7 265.4-1.1 2.8.5 1.1.4-1.1.8-3.1zm-34.6-7-.7-1 1-.1 1-.9.4-1.8-.2-.5.1-.5-.3-.7-.6-.5-.4-.1-.5-.4-.6-.6h-1l-.6-.1-.4-.4.1-.5-1.7-.6-.8.3-1.2-.1-.7-.7-.5-.2-.2-.7.6-.8v-.9l-1.2-.2-1-.9-.9.1-1.6-.3-.4.7-.4 1.6-.5 2.3-10-5.2-.2.9.9 1.6-.8 2.3.1 2.9-1.2.8-.5 2.1-.9.8-1.4 1.8-.9.8-1 2.5-2.4-1.1-2.3 8.5-1.3 1.6-2.8-.5-1.3-1.9-2.3-.7-.1 4.7-1.4 1.7.4 1.5-2.1 2.2.4 1.9-3.7 6.3-1 3.3 1.5 1.2-1.5 1.9.1 1.4-2.3 2-.7-1.1-4.3 3.1-1.5-1-.6 1.4.8.5-.5.9-5.5 2.4-3-1.8-.8 1.7-1.9 1.8-2.3.1-4.4-2.3-.1-1.5-1.5-.7.8-1.2-.7-.6-4.9 6.6-2.9 1-3 3-.4 2.2-2.1 1.3-.1 1.7-1.4 1.4-1.8.5-.5 1.9-1 .4-6.9 4.2 28.9-3.3.2-1 4.6-.5-.3.7 29.4-3.5 39.4-7.3 29.1-6.1-.6-1.2.4-.1.9.9-.1-1.4-.3-1.9 1.6 1.2.9 2.1v-1.3l-3.4-5.5v-1.2l-.7-.8-1.3.7.5 1.4h-.8l-.4-1-.6.9-.9-1.1-2.1-.1-.2.7 1.5 2.1-1.4-.7-.5-1-.4.8-.8.1-1.5 1.7.3-1.6v-1.4l-1.5-.7-1.8-.5-.2-1.7-.6-1.3-.6 1.1-1.7-1-2 .3.2-.9 1.5-.2.9.5 1.7-.8.9.4.5 1v.7l1.9.4.3.9.9.4.9 1.2 1.4-1.6h.6l-.1-2.1-1.3 1-.6-.9 1.5-.2-1.2-.9-1.2.6-.1-1.7-1.7.2-2.2-1.1-1.8-2.2 3.6 2.2.9.3 1.7-.8-1.7-.9.6-.6-1-.5.8-.2-.3-.9 1.1.9.4-.8.4 1.3 1.2.8.6-.5-.5-.6-.1-2.5-1.1-.1-1.6-.8.9-1.1-2-.1-.4-.5-1.4.6-1.4-.8-.5-1.2-2.1-1.2-2.1-1.8-2.2-1.9 3 1.3.9 1.2 2.1.7 2.3 2.5.2-1.7.6 1.3 2.3.5v-4l-.8-1.1 1.1.4.1-1.6-3.1-1.4-1.6-.2-1.3-.2.3-1.2-1.5-.3-.1-.6h-1.8l-.2.8-.7-1h-2.7l-1-.4-.2-1-1.2-.6-.4-1.5-.6-.4-.7 1.1-.9.2-.9.7h-1.5l-.9-1.3.4-3.1.5-2.4.6.5zM822 270l.9-.1v-.6l-.8.1zm7.5 14.2-1 2.7 1.2-1.3zm-1.8-15.3.7.3-.2 1.9-.5-.5-1.3 1 1 .4-1.8 4.4.1 8.1 1.9 3.1.5-1.5.4-2.7-.3-2.3.7-.9-.2-1.4 1.2-.6-.6-.5.5-.7.8 1.1-.2 1.1-.4 3.9 1.1-2.2.4-3.1.1-3-.3-2 .6-2.3 1.1-1.8.1-2.2.3-.9-4.6 1.6-.7.8z',
	},
	{
		title: 'Washington',
		abbreviation: 'wa',
		svgPath:
			'm161.9 83.6.7 4-1.1 4.3-30.3-7.3-2.8 1-5.4-.9-1.8-.9-1.5 1.2-3.3-.4-4.5.5-.9.7-4.2-.4-.8-1.6-1.2-.2-4.4 1.3-1.6-1.1-2.2.8-.2-1.8-2.3-1.2-1.5-.2-1-1.1-3 .3-1.2-.8h-1.2l-1.2.9-5.5.7-6.6-4.2 1.1-5.6-.4-4.1-3.2-3.7-3.7.1-.4-1.1.4-1.2-.7-.8-1 .1-2.1-1.5-1.2.4-2-.1-.7-1.5-1.6-.3 2.5-7.5-.7 6 .5.5v-2l.8-.2 1.1 2.3-.5-2.2 1.2-4.2 1.8.4-1.1-2-1 .3-1.5-.4.2-4.2.2 1.5.9.5.6-1.6h3.2l-2.2-1.2-1.7-1.9-1.4 1.6 1.2-3.1-.3-4.6-.2-3.6.9-6.1-.5-2-1.4-2.1.1-4 .4-2.7 2-2.3-.7-1.4.2-.6.9.1 7.8 7.6 4.7 1.9 5.1 2.5 3.2-.1.2 3 1-1.6h.7l.6 2.7.5-2.6 1.4-.2.5.7-1.1.6.1 1.6.7-1.5h1.1l-.4 2.6-1.1-.8.4 1.4-.1 1.5-.8.7-2.5 2.9 1.2-3.4-1.6.4-.4 2.1-3.8 2.8-.4 1-2.1 2.2-.1 1h2.2l2.4-.2.5-.9-3.9.5V39l2.6-2.8 1.8-.8 1.9-.2 1-1.6 3-2.3v-1.4h1.1l.1 4h-1.5l-.6.8-1.1-.9.3 1.1v1.7l-.7.7-.3-1.6-.8.8.7.6-.9 1.1h1.3l.7-.5.1 2-1 1.9-.9 1-.1 1.8-1-.2-.2-1.4.9-1.1-.8-.5-.8.7-.7 2.2-.8.9-.1-2 .8-1.1-.2-1.1-1.2 1.2.1 2.2-.6.4-2.1-.4-1.3 1.2 2.2-.6-.2 2.2 1-1.8.4 1.4.5-1 .7 1.8h.7l.7-.8.6-.1 2-1.9.2-1.2.8.6.3.9.7-.3.1-1.2h1.3l.2-2.9-.1-2.7.9.3-.7-2.1 1.4-.8.2-2.4 2.3-2.2 1 .1.3-1.4-1.2-1.4-.1-3.5-.8.9.7 2.9-.6.1-.6-1.9-.6-.5.3-2.3 1.8-.1.3.7.3-1.6-1.6-1.7-.6-1.6-.2 2 .9 1.1-.7.4-1-.8-1.8 1.3 1.5.5.2 2.4L91 28l.9-1.3 1.4 2.3-.4 1.9h-1.5v-1.2l-1.5-1.2.5-3-1.9-2.6 2.7-3 .6-4.1h.9l1.4 3.2v-2.6l1.2.3v-3.3l-.9-.8-1.2 2.5-1-3 1.3-.1L92 7.1l1.9-.6 25.4 7.5 31.7 8 23.6 5.5zM83.2 44.2h.5l.1.8-.5.3.1.6-.7.4-.2-.9.5-.4zm5-4.3L87 41.8l-.1.8.4.2.5-.6 1.1.1zm-.4-21.6.5.6 1.3-.3.2-1 1.2-1.8-1-.4-.7 1.6-.1-1.6-1.1.2-.7 1.4zm3.2-5.5.7 1.5-.9.2-.8.4-.2-2.4zm-2.7-1.6-1.1-.2.5 1.4zm-1 2.5.8.4-.4 1.1 1.7-.5-.2-2.2-.9-.2zm-2.7-.4.3 2.7 1.6 1.3.6-1.9-1.1-2.2zm1.9-1.1-1.1-1-.9.1 1.8 1.5zm3.2-7h-1.2V6l1.2.6zm-.9 32.5.4-2.7h-1.1l-.2 1.9z',
	},
	{ title: 'Washington DC', abbreviation: 'dc' },
	{
		title: 'West Virginia',
		abbreviation: 'wv',
		svgPath:
			'm723.4 297.5-.8 1.2 1.5.7.1 1.5 4.4 2.3 2.3-.1 1.9-1.8.8-1.7 3 1.8 5.5-2.4.5-.9-.8-.5.6-1.4 1.5 1 4.3-3.1.7 1.1 2.3-2-.1-1.4 1.5-1.9-1.5-1.2 1-3.3 3.7-6.3-.4-1.9 2.1-2.2-.4-1.5 1.4-1.7.1-4.7 2.3.7 1.3 1.9 2.8.5 1.3-1.6 2.3-8.5 2.4 1.1 1-2.5.9-.8 1.4-1.8.9-.8.5-2.1 1.2-.8-.1-2.9.8-2.3-.9-1.6.2-.9 10 5.2.5-2.3.4-1.6.4-.7-.9-.4.2-1.6-1-.5-.2-.7h-.7l-.8-1.2.2-1-2.6.4-2.2-1.6-1.4.3-.9 1.4h-1.3l-1.7 2.9-3.3.4-1.9-1-2.6 3.8-2.2-.3-3.1 3.9-.9 1.6-1.8 1.6-1.7-11.4-17.4 2.9-3.2-19.7-2.2 1.2 1.4 2.1-.1 2.2.6 2-1.1 3.4-.1 5.4-1 3.6.5 1.1-.4 2.2-1.1.5-2 3.3-1.8 2h-.6l-1.8 1.7-1.3-1.2-1.5 1.8-.3 1.2h-1.3l-1.3 2.2.1 2.1-1 .5 1.4 1.1v1.9l-1 .2-.7.8-1 .5-.6-2.1-1.6-.5-1 2.3-.3 2.2-1.1 1.3 1.3 3.6-1.5.8-.4 3.5h-1.5l-3.2 1.4-.1 1.1.6 1-.6 3.6 1.9 1.6.8 1.1 1 .6-.1.9 4.4 5.6h1.4l1.5 1.8 1.2.3 1.4-.1z',
	},
	{
		title: 'Wisconsin',
		abbreviation: 'wi',
		svgPath:
			'm611 144-2.9.8.2 2.3-2.4 3.4-.2 3.1.6.7.8-.7.5-1.6 2-1.1 1.6-4.2 3.5-1.1.8-3.3.7-.9.4-2.1 1.8-1.1v-1.5l1-.9 1.4.1v2l-1 .1.5 1.2-.7 2.2-.6.1-1.2 4.5-.7.5-2.8 7.2-.3 4.2.6 2 .1 1.3-2.4 1.9.3 1.9-.9 3.1.3 1.6.4 3.7-1.1 4.1-1.5 5 1 1.5-.3.3.8 1.7-.5 1.1 1.1.9v2.7l1.3 1.5-.4 3 .3 4-45.9 2.8-1.3-2.8-3.3-.7-2.7-1.5-2-5.5.1-2.5 1.6-3.3-.6-1.1-2.1-1.6-.2-2.6-1.1-4.5-.2-3-2.2-3-2.8-.7-5.2-3.6-.6-3.3-6.3-3.1-.2-1.3h-3.3l-2.2-2.6-2-1.3.7-5.1-.9-1.6.5-5.4 1-1.8-.3-2.7-1.2-1.3-1.8-.3v-1.7l2.8-5.8 5.9-3.9-.4-13 .9.4.6-.5.1-1.1.9-.6 1.4 1.2.7-.1h2.6l6.8-2.6.3-1h1.2l.7-1.2.4.8 1.8-.9 1.8-1.7.3.5 1-1 2.2 1.6-.8 1.6-1.2 1.4.5 1.5-1.4 1.6.4.9 2.3-1.1v-1.4l3.3 1.9 1.9.7 1.9.7 3 3.8 17 3.8 1.4 1 4 .8.7.5 2.8-.2 4.9.8 1.4 1.5-1 1 .8.8 3.8.7 1.2 1.2.1 4.4-1.3 2.8 2 .1 1-.8.9.8-1.1 3.1 1 1.6 1.2.3zm-49.5-37.3-.5.1-1.5 1.6.2.5 1.5-.6v-.6l.9-.3zm1.6-1.1-1 .3-.2.7.9-.1zm-1.3-1.6-.2.9h1.7l.6-.4.1-1zm2.8-3-.3 1.9 1.2-.5.1-1.4zm58.3 31.9-2 .3-.4 1.3 1.3 1.7z',
	},
	{
		title: 'Wyoming',
		abbreviation: 'wy',
		svgPath: 'm355.3 143.7-51-5.3-57.3-7.9-2 10.7-8.5 54.8-3.3 21.9 32.1 4.8 44.9 5.7 37.5 3.4 3.7-44.2z',
	},
];
